import { Text } from '@/components/Text';
import { LucideIcon } from 'lucide-react';

interface IconCardContent {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface IconCardProps extends IconCardContent {
  className?: string;
}

interface IconCardGridProps {
  content: {
    sectionTitle: string;
    sectionSubtitle?: string;
    cards: IconCardContent[];
  }
}

function IconCard({ icon, title, description }: IconCardProps): JSX.Element {
  return (
    <div className="group relative rounded-xl cursor-pointer h-full">
      <div className="absolute left-[3px] top-[3px] h-full w-full bg-black-100 rounded-[15px] z-0" />
      <div className="relative z-1 overflow-hidden rounded-[inherit] border-2 border-black group-hover:border-pink p-6 
                    left-[0] top-[0] group-active:left-[1px] group-active:top-[1px] transition-all duration-200 h-full">
        <div className="flex gap-4 h-full">
          <div className="w-12 h-12 flex-shrink-0 bg-pink rounded-lg flex items-center justify-center">
            {icon}
          </div>
          <div className="flex-1">
            <Text variant="h4" textColor="white">
              {title}
            </Text>
            <Text variant="body-1" className="text-white/75 mt-2">
              {description}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export function IconCardGrid({ content }: IconCardGridProps): JSX.Element {
  const { sectionTitle, sectionSubtitle, cards } = content;
  
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="text-center mb-12">
        <Text variant="h3" textColor="white">
          {sectionTitle}
        </Text>
        {sectionSubtitle && (
          <Text variant="h4" textColor="white" className="opacity-70 mt-2">
            {sectionSubtitle}
          </Text>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl">
        {cards.map((card, index) => (
          <IconCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
}