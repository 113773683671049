import { Text } from '@/components/Text';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';

interface AccordionItem {
  id: string;
  question: string;
  answer: string;
}

interface AccordionListProps {
  content: {
    sectionTitle: string;
    sectionSubtitle?: string;
    items: AccordionItem[];
  }
}

function AccordionItem({ 
  id, 
  question, 
  answer,
  isOpen,
  onToggle 
}: AccordionItem & { 
  isOpen: boolean; 
  onToggle: (id: string) => void;
}): JSX.Element {
  return (
    <div className="group relative rounded-xl">
      <div 
        className="relative z-1 overflow-hidden rounded-xl border-2 border-black hover:border-pink p-6 cursor-pointer"
        onClick={() => onToggle(id)}
      >
        <div className="flex justify-between items-center">
          <Text variant="h4" textColor="white">
            {question}
          </Text>
          <ChevronDown 
            className={`w-6 h-6 text-white transition-transform duration-200 ${
              isOpen ? 'rotate-180' : ''
            }`}
          />
        </div>
        {isOpen && (
          <Text variant="body-1" className="text-white/75 mt-4">
            {answer}
          </Text>
        )}
      </div>
    </div>
  );
}

export function AccordionList({ content }: AccordionListProps): JSX.Element {
  const { sectionTitle, sectionSubtitle, items } = content;
  const [openItem, setOpenItem] = useState<string | null>(null);

  const toggleItem = (id: string) => {
    setOpenItem(openItem === id ? null : id);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="text-center mb-12">
        <Text variant="h3" textColor="white">
          {sectionTitle}
        </Text>
        {sectionSubtitle && (
          <Text variant="h4" textColor="white" className="opacity-70 mt-2">
            {sectionSubtitle}
          </Text>
        )}
      </div>

      <div className="w-full max-w-6xl space-y-4">
        {items.map((item) => (
          <AccordionItem
            key={item.id}
            {...item}
            isOpen={openItem === item.id}
            onToggle={toggleItem}
          />
        ))}
      </div>
    </div>
  );
}