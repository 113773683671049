import { Text } from '@/components/Text';
import { Button } from '@/components/buttons/Button';
import Image from 'next/image';

interface StaggeredContent {
  title: string;
  subtitle: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  imageSrc: string;
  imageAlt: string;
}

interface StaggeredBlockProps extends StaggeredContent {
  imageOnRight: boolean;
}

interface StaggeredContentLayoutProps {
  sections: StaggeredContent[];
}

// Individual block component
function StaggeredBlock({ 
  title, 
  subtitle, 
  description, 
  buttonText, 
  buttonAction, 
  imageSrc, 
  imageAlt, 
  imageOnRight 
}: StaggeredBlockProps): JSX.Element {
  const TextContent = () => (
    <div className="flex flex-col space-y-6 md:w-1/2">
      <Text variant="h3" textColor="white">
        {title}
      </Text>
      <Text variant="h4" textColor="white" className="opacity-70">
        {subtitle}
      </Text>
      <Text variant="body-1" className="text-white/75">
        {description}
      </Text>
      <Button
        className="w-full md:w-auto"
        wrapperClass="bg-pink-gr group-active/button:bg-white-gr px-8 py-3"
        onClick={buttonAction}
      >
        <Text 
          className="transition-all duration-150 text-white group-active/button:text-black"
          variant="btn-1"
        >
          {buttonText}
        </Text>
      </Button>
    </div>
  );

  const ImageContent = () => (
    <div className="md:w-1/2 h-full">
      <Image
        src={imageSrc}
        alt={imageAlt}
        width={500}
        height={400}
        className="rounded-lg object-cover w-full h-full"
      />
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row items-center justify-between gap-8">
      {imageOnRight ? (
        <>
          <TextContent />
          <ImageContent />
        </>
      ) : (
        <>
          <ImageContent />
          <TextContent />
        </>
      )}
    </div>
  );
}

// Main layout component
export function StaggeredContentLayout({ sections }: StaggeredContentLayoutProps): JSX.Element {
  return (
    <div className="flex flex-col space-y-12">
      {sections.map((section, index) => (
        <StaggeredBlock
          key={index}
          {...section}
          imageOnRight={index % 2 === 0}
        />
      ))}
    </div>
  );
}